import { AnyAction } from 'redux';
import { WhatsappBalanceDayData } from '../types/meta/whatsappStatistics';

interface StatisticsState {
  contacts: {
    personalCount?: number;
    totalCount?: number;
  };
  stats: {
    userCount?: number;
    customerCount?: number;
    sendSmsCount?: number;
    campaignCount?: number;
    revenueDaily?: number;
    revenueMonthly?: number;
    revenueDailyYesterday?: number;
    volumeDaily?: number;
    volumeMonthly?: number;
    volumeDailyYesterday?: number;
    currency?: string;
    monthlyTraffic: Array<number | null>;
    monthlyTrafficLoading: boolean;
    dailyTraffic: Array<number | null>;
    dailyTrafficLoading: boolean;
  };
  campaigns: {
    lastDelivered?: number;
    lastName?: string;
    lastSent?: number;
    nextContactCount?: number;
    nextName?: string;
    runningDelivered?: number;
    runningName?: string;
    runningSent?: number;
  };
  balanceSummary: $TSFixMe[];
  balanceAllPaid?: number;
  balanceCurrency?: string;
  data?: $TSFixMe;
  viberDailyStats: {
    dailyStats: { [key: string]: number };
    allMessagesCount: number;
    transactionalMessagesCount: number;
    promotionalMessagesCount: number;
    totalUsage: number;
    balance: number;
    revenue: number;
  };
  viberDailyStatsLoading: boolean;
  balanceService: string;
  viberMessagesStats: { count: number };
  viberMonthlyStats: { [key: number]: number };
  viberMonthlyStatsLoading: boolean;
  whatsappDailyStats: {
    dailyStats: { [key: string]: number };
    allConversationCount: number;
    authenticationConversationCount: number;
    marketingConversationCount: number;
    totalUsage: number;
    balance: number;
    revenue: number;
    serviceConversationCount: number;
    utilityConversationCount: number;
  };
  whatsappDailyStatsLoading: boolean;
  whatsappMonthlyStats: { [key: string]: number };
  whatsappMonthlyStatsLoading: boolean;
  whatsappBalanceDailyStats: { [date: string]: WhatsappBalanceDayData };
  whatsappBalanceDailyStatsLoading: boolean;
}

const initialState: StatisticsState = {
  contacts: {
    personalCount: undefined,
    totalCount: undefined,
  },
  stats: {
    userCount: undefined,
    customerCount: undefined,
    sendSmsCount: undefined,
    campaignCount: undefined,
    revenueDaily: undefined,
    revenueMonthly: undefined,
    revenueDailyYesterday: undefined,
    volumeDaily: undefined,
    volumeMonthly: undefined,
    volumeDailyYesterday: undefined,
    currency: '',
    monthlyTraffic: [],
    monthlyTrafficLoading: false,
    dailyTraffic: [],
    dailyTrafficLoading: false,
  },
  campaigns: {
    lastDelivered: 0,
    lastName: undefined,
    lastSent: 0,
    nextContactCount: 0,
    nextName: undefined,
    runningDelivered: 0,
    runningName: undefined,
    runningSent: 0,
  },
  balanceSummary: [],
  balanceAllPaid: 0,
  balanceCurrency: '',
  viberDailyStats: {
    dailyStats: {},
    allMessagesCount: 0,
    transactionalMessagesCount: 0,
    promotionalMessagesCount: 0,
    totalUsage: 0,
    balance: 0,
    revenue: 0,
  },
  viberDailyStatsLoading: false,
  balanceService: 'ALL',
  viberMessagesStats: { count: 0 },
  viberMonthlyStats: {},
  viberMonthlyStatsLoading: false,
  whatsappDailyStats: {
    dailyStats: {},
    allConversationCount: 0,
    authenticationConversationCount: 0,
    marketingConversationCount: 0,
    totalUsage: 0,
    balance: 0,
    revenue: 0,
    serviceConversationCount: 0,
    utilityConversationCount: 0,
  },
  whatsappDailyStatsLoading: false,
  whatsappMonthlyStats: {},
  whatsappMonthlyStatsLoading: false,
  whatsappBalanceDailyStats: {},
  whatsappBalanceDailyStatsLoading: false,
};

export default function reducer(state: StatisticsState = initialState, action: AnyAction): StatisticsState {
  switch (action.type) {
    case 'GET_COUNTS_CONTACTS_SUCCESS': {
      return { ...state, contacts: action.data };
    }
    case 'GET_FAILED': {
      return {
        ...state,
        data: {},
      };
    }
    case 'GET_STATISTICS_USER': {
      return {
        ...state,
        stats: { ...state.stats, ...action.data },
      };
    }
    case 'GET_REVENUE': {
      return {
        ...state,
        stats: { ...state.stats, ...action.data },
      };
    }
    case 'GET_STATISTICS_CAMPAIGN': {
      return {
        ...state,
        campaigns: action.data,
      };
    }
    case 'SET_MONTHLY_CHART_LOADING': {
      return {
        ...state,
        stats: { ...state.stats, monthlyTrafficLoading: true },
      };
    }
    case 'SET_MONTHLY_CHART_DATA': {
      return {
        ...state,
        stats: {
          ...state.stats,
          monthlyTraffic: action.data,
          monthlyTrafficLoading: false,
        },
      };
    }
    case 'SET_DAILY_CHART_LOADING': {
      return {
        ...state,
        stats: { ...state.stats, dailyTrafficLoading: true },
      };
    }
    case 'SET_DAILY_CHART_DATA': {
      return {
        ...state,
        stats: {
          ...state.stats,
          dailyTraffic: action.data,
          dailyTrafficLoading: false,
        },
      };
    }
    case 'SET_BALANCE_TABLE_SUMMARY': {
      return {
        ...state,
        balanceSummary: action.data,
      };
    }
    case 'SET_BALANCE_ALL_PAID': {
      return {
        ...state,
        balanceAllPaid: action.data,
      };
    }
    case 'SET_BALANCE_CURRENCY': {
      return {
        ...state,
        balanceCurrency: action.data,
      };
    }
    case 'GET_VIBER_DAILY_STATS': {
      return {
        ...state,
        viberDailyStats: action.data,
      };
    }
    case 'SET_VIBER_DAILY_STATS_LOADING': {
      return {
        ...state,
        viberDailyStatsLoading: action.payload,
      };
    }
    case 'SET_BALANCE_SERVICE': {
      return {
        ...state,
        balanceService: action.data,
      };
    }
    case 'GET_VIBER_MESSAGES_STATS': {
      return {
        ...state,
        viberMessagesStats: action.data,
      };
    }
    case 'GET_VIBER_MONTHLY_STATS': {
      return {
        ...state,
        viberMonthlyStats: action.data,
      };
    }
    case 'SET_VIBER_MONTHLY_STATS_LOADING': {
      return {
        ...state,
        viberMonthlyStatsLoading: action.payload,
      };
    }
    case 'SET_WHATSAPP_DAILY_STATS_LOADING': {
      return {
        ...state,
        whatsappDailyStatsLoading: action.payload,
      };
    }
    case 'GET_WHATSAPP_DAILY_STATS': {
      return {
        ...state,
        whatsappDailyStats: action.data,
      };
    }
    case 'GET_WHATSAPP_MONTHLY_STATS': {
      return {
        ...state,
        whatsappMonthlyStats: action.data,
      };
    }
    case 'SET_WHATSAPP_MONTHLY_STATS_LOADING': {
      return {
        ...state,
        whatsappMonthlyStatsLoading: action.payload,
      };
    }
    case 'SET_WHATSAPP_BALANCE_DAILY_STATS_LOADING': {
      return {
        ...state,
        whatsappBalanceDailyStatsLoading: action.payload,
      };
    }
    case 'GET_WHATSAPP_BALANCE_DAILY_STATS': {
      return {
        ...state,
        whatsappBalanceDailyStats: action.data,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
