import { HttpClient } from '../common/httpClient';
import {
  WhatsappBalanceDayData,
  WhatsappBalanceDayDataStructure,
  WhatsappBalanceOverview,
  WhatsappMonthlyBalanceOverview,
} from '../../types/meta/whatsappStatistics';

export type WhatsappBalanceOverviewResponse = {
  readonly allConversationCount: number;
  readonly authenticationConversationCount: number;
  readonly balance: number;
  readonly dailyStats: { [key: string]: number };
  readonly marketingConversationCount: number;
  readonly revenue: number;
  readonly totalUsage: number;
  readonly serviceConversationCount: number;
  readonly utilityConversationCount: number;
};

export type WhatsappMonthlyBalanceOverviewResponse = {
  readonly monthlyCount: { [key: number]: number };
};

export interface WhatsappDailyBalanceResponse {
  readonly dailyStats: { [date: string]: WhatsappBalanceDayData };
};

export class WhatsappStatisticsApi {
  constructor(private readonly httpClient: HttpClient) {}

  private mapToWhatsappBalanceOverview(response: WhatsappBalanceOverviewResponse): WhatsappBalanceOverview {
    return {
      allConversationCount: response.allConversationCount,
      authenticationConversationCount: response.authenticationConversationCount,
      balance: response.balance,
      dailyStats: response.dailyStats,
      marketingConversationCount: response.marketingConversationCount,
      revenue: response.revenue,
      totalUsage: response.totalUsage,
      serviceConversationCount: response.serviceConversationCount,
      utilityConversationCount: response.utilityConversationCount,
    };
  }

  private mapToWhatsappMonthlyBalanceOverview(response: WhatsappMonthlyBalanceOverviewResponse): WhatsappMonthlyBalanceOverview {
    return {
      monthlyCount: response.monthlyCount,
    };
  }

  private mapToWhatsappDailyBalance(response: WhatsappDailyBalanceResponse): WhatsappBalanceDayDataStructure {
    return {
      dailyStats: response.dailyStats,
    };
  }

  getUserDailyStats(tenantId: string, from: string, to: string): Promise<WhatsappBalanceOverview> {
    return this.httpClient.get<WhatsappBalanceOverviewResponse>(`${tenantId}/statistics/user-daily-count?from=${from}&to=${to}`)
      .then(data => this.mapToWhatsappBalanceOverview(data));
  }

  getUserDailyBalanceOverview(id: string, from: string, to: string): Promise<WhatsappBalanceDayDataStructure> {
    return this.httpClient.get<WhatsappDailyBalanceResponse>(`${id}/statistics/user-balance-overview?from=${from}&to=${to}`)
      .then(data => this.mapToWhatsappDailyBalance(data));
  }

  getUserMonthlyStats(tenantId: string, year: number): Promise<WhatsappMonthlyBalanceOverview> {
    return this.httpClient.get<WhatsappMonthlyBalanceOverviewResponse>(`${tenantId}/statistics/user-monthly-count?year=${year}`)
      .then(data => this.mapToWhatsappMonthlyBalanceOverview(data));
  }

  getAdminBalanceOverview(from: string, to: string, appId: string): Promise<WhatsappBalanceOverview> {
    return this.httpClient.get<WhatsappBalanceOverviewResponse>(`statistics/user-balance-overview?from=${from}&to=${to}&appId=${appId}`)
      .then(data => this.mapToWhatsappBalanceOverview(data));
  }

  getAdminMonthlyStats(year: number, appId: string): Promise<WhatsappMonthlyBalanceOverview> {
    return this.httpClient.get<WhatsappMonthlyBalanceOverviewResponse>(`statistics/user-monthly-count?year=${year}&appId=${appId}`)
      .then(data => this.mapToWhatsappMonthlyBalanceOverview(data));
  }

}
